import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useSelector, useDispatch } from "react-redux";
import { IoExitOutline } from "react-icons/io5";
import { AiOutlineMenu } from "react-icons/ai";

//ui
import ModalDash from "../../pages/DashboardLight/Modal";

//redux
import { menuOpen, setMenuOpen } from "../../store/dashboard/dashboard.slice";

//styles
import "./../../assets/css/dashboard-light/dashboard-light.css";

//icons
import LogoWizard from "../../assets/images/client-logo/pearson-wizard.png";
import LogoYazigi from "../../assets/images/client-logo/pearson-yazigi.png";

//pages
import Dashboard from "../../pages/DashboardLight/Dashs/Dashboard";
import PearsonWizard from "../../pages/DashboardLight/Dashs/PearsonWizard";
import PearsonYazigi from "../../pages/DashboardLight/Dashs/PearsonYazigi";
import PearsonWizardEspanhol from "../../pages/DashboardLight/Dashs/PearsonWizardEspanhol";
import PearsonWizard2024 from "../../pages/DashboardLight/Dashs/PearsonWizard2024";
import PearsonWizardEspanhol2024 from "../../pages/DashboardLight/Dashs/PearsonWizardEspanhol2024";

//utils
import { clearStorages } from "../../utils/Storage";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels,
  BarElement
);

const DashboardLightPage = () => {
  const { dashid } = useParams();
  const dispatch = useDispatch();

  const dashList = {
    "dashboard": {
      dash: <Dashboard />,
      logo: LogoWizard,
      name: "Dashboard",
    },
    "pearson-wizard": {
      dash: <PearsonWizard />,
      logo: LogoWizard,
      name: "Pearson Wizard - 2023",
    },
    "pearson-wizard-espanhol": {
      dash: <PearsonWizardEspanhol />,
      logo: LogoWizard,
      name: "Pearson Wizard (Espanhol) - 2023",
    },
    "pearson-yazigi": {
      dash: <PearsonYazigi />,
      logo: LogoYazigi,
      name: "Pearson Yázigi",
    },
    "pearson-wizard-2024": {
      dash: <PearsonWizard2024 />,
      logo: LogoWizard,
      name: "Pearson Wizard - Atual",
    },
    "pearson-wizard-espanhol-2024": {
      dash: <PearsonWizardEspanhol2024 />,
      logo: LogoWizard,
      name: "Pearson Wizard Espanhol - Atual",
    },
  }[dashid];

  const menuOpenRedux = useSelector(menuOpen);
  const [docWidth, setDocWidth] = useState(100000);

  const toggleMenu = () => {
    dispatch(setMenuOpen(!menuOpenRedux));
  };

  const checkWidthDocument = () => {
    const docW = document.documentElement.clientWidth;
    let fun = null;
    fun = setTimeout(() => {
      clearTimeout(fun);
      setDocWidth(docW);
    }, 200);
  };

  window.addEventListener("resize", () => {
    checkWidthDocument();
  });

  useEffect(() => {
    checkWidthDocument();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {dashList === undefined && "Dashboard não encontrado!"}
      {dashList !== undefined && (
        <>
          <ModalDash />
          <header id="header-dashboard-light">
            <div className="header-layout">
              {docWidth <= 960 && (
                <div
                  className="menu"
                  id="menu-main"
                  onClick={() => toggleMenu()}
                >
                  <AiOutlineMenu />
                </div>
              )}
              <img
                src={dashList.logo}
                alt="Logo Cliente"
                className="logo-light"
              />
              <div className="dash-name">{dashList.name}</div>
              <IoExitOutline
                alt="Botão Sair"
                className="logo-exit"
                title="Sair"
                onClick={() => {
                  clearStorages();
                  window.location = "/";
                }}
              />
            </div>
          </header>
          {dashList.dash}
        </>
      )}
    </>
  );
};

export default DashboardLightPage;
